import { useMemo } from 'react';

import { FeatureFlags } from '@sbiz/business';

import { ApiCacheOptions, ApiCacheStorageOptions } from '../common/api';
import { useApiCache } from '../common/api/hooks/useApiCache';

const STORAGE_OPTIONS = { expiresIn: 1_000 * 60 * 5 } as const satisfies ApiCacheStorageOptions;

export function useFeatureFlags(options?: Omit<ApiCacheOptions<FeatureFlags>, 'isPublic'>) {
  const { data } = useApiCache('featureFlag', '', {
    ...options,
    isPublic: true,
    keepPreviousData: true,
    storage: STORAGE_OPTIONS,
  });

  return useMemo(() => (data === null ? {} : data), [data]);
}
