import { useCallback, useMemo } from 'react';
import useSWR, { State } from 'swr';

import { ApiError, getStorageItem } from '@sbiz/util-browser';

import { API_CACHE_STORAGE_KEY_PREFIX } from '../../constants';
import { ApiData, ApiRecord, ResourceType } from '../resources';
import { ApiCacheOptions } from '../types';
import { getFullPath } from '../utils';
import { useApi } from './useApi';
import { useClearCache } from './useClearCache';

export function useApiCache<const T extends ResourceType, TData extends ApiData = ApiRecord<T>>(
  resourceType: T,
  path: string | undefined,
  options?: ApiCacheOptions<TData>,
) {
  const { isPaused, isPublic, params, storage, ...swrOptions } = options ?? {};

  const { basePath, get } = useApi(resourceType);
  const clearCache = useClearCache();

  const storageType = useMemo(() => {
    if (storage) {
      if (storage === true) {
        return 'session';
      }

      return storage.type === 'local' ? 'local' : 'session';
    }
  }, [storage]);

  const storageExpiresIn = useMemo(() => {
    if (storage) {
      if (storage === true || storage.expiresIn === undefined) {
        return Infinity;
      }

      return storage.expiresIn;
    }
  }, [storage]);

  const storagePrefix = useMemo(() => {
    if (storageType && storageExpiresIn !== undefined) {
      return `${API_CACHE_STORAGE_KEY_PREFIX}:${storageType}:${storageExpiresIn}:`;
    }

    return '';
  }, [storageExpiresIn, storageType]);

  const apiCacheKey = useMemo(() => {
    if (typeof path !== 'string' || isPaused?.()) {
      return null;
    }

    const fullPath = getFullPath(basePath, path);

    return `${storagePrefix}${fullPath}${params ? `:${JSON.stringify(params)}` : ''}`;
  }, [basePath, isPaused, params, path, storagePrefix]);

  const isDataInStorage = useMemo(() => {
    if (apiCacheKey && storageType) {
      const state = getStorageItem<{ expiresAt?: number } & State<TData>>(apiCacheKey, storageType);

      if (state && (state.expiresAt === undefined || state.expiresAt > Date.now())) {
        return state.data !== undefined && !state.isLoading && !state.isValidating;
      }
    }

    return false;
  }, [apiCacheKey, storageType]);

  const clear = useCallback(
    (clearPath?: string) => {
      clearCache(resourceType, { path: clearPath });
    },
    [clearCache, resourceType],
  );

  const fetcher = useCallback(async () => {
    const { data, error } = await get<TData>(path ?? '', { isPublic, params });

    if (data) {
      return data;
    }

    throw error;
  }, [get, isPublic, params, path]);

  const useSWROptions = useMemo(() => {
    if (isDataInStorage) {
      return { ...swrOptions, revalidateOnMount: false };
    }

    return swrOptions;
  }, [isDataInStorage, swrOptions]);

  const { data, error, isLoading, isValidating, mutate } = useSWR<TData, ApiError>(apiCacheKey, fetcher, useSWROptions);
  const apiCacheData = error ? null : data;
  const isLoadingOrValidating = isLoading || isValidating;

  return useMemo(
    () => ({ clear, data: apiCacheData, error, isLoading: isLoadingOrValidating, mutate }),
    [apiCacheData, clear, error, isLoadingOrValidating, mutate],
  );
}
