import { createContext, ReactNode, useContext } from 'react';

import { FeatureFlags } from '@sbiz/business';

import { useFeatureFlags } from '../hooks/useFeatureFlags';

const FeatureFlagsContext = createContext<FeatureFlags>({});

export function FeatureFlagsContextProvider({ children }: { children: ReactNode }) {
  const featureFlags = useFeatureFlags();

  return featureFlags === undefined ? null : (
    <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlagsContext() {
  return useContext(FeatureFlagsContext);
}
